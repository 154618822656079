/* global fetch */
import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import App from './App'
import reducers, { getDatasets } from './store'
import './index.css'

import { setUser } from './store'

// Set up the Chrome Redux dev tools
const composers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composers(applyMiddleware(thunk)))

const authUrl =
  window.location.hostname === 'admin.liveby.com'
    ? 'https://api.liveby.com'
    : 'https://dev.api.liveby.com'

fetch(`${authUrl}/google/me`, {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
  credentials: 'include'
})
  .then((res) => {
    return res.json()
  })
  .catch((e) => {
    console.error(e)
    window.location = authUrl + '/google/authenticate'
  })
  .then(({ user }) => {
    if (user !== null) {
      store.dispatch(setUser(user))
      store.dispatch(getDatasets())
      render(
        <Provider store={store}>
          <Router>
            <Route
              path='/'
              render={(routes) => <App user={user} {...routes} />}
            />
          </Router>
        </Provider>,
        document.getElementById('root')
      )
    } else {
      window.location = authUrl + '/google/authenticate'
    }
  })

// Disable for now
// import registerServiceWorker from './registerServiceWorker'
// registerServiceWorker()
