// @flow
/* global fetch */
import PromiseMap from 'promise.map'
import { coordEach, round } from '@turf/turf'
const { cityName } = require('../utils')
/**
 * store
 * =====
 *
 * The redux store maintains state for the admin application when state needs
 * to be accessible to multiple UI components.
 *
 * If you need to access the state, you can do so from a connected component by
 * mapping state to props, and mapping actions to props.
 *
 * `thunk` is already included as middleware, so feel free to use it when you
 * need to make async actions.
 *
 * When possible, please follow FSA:
 * https://github.com/acdlite/flux-standard-action#flux-standard-action
 */

//
// API
//

export const API =
  /.*\.liveby\.com/.test(window.location.host) &&
  !window.location.host.includes('local')
    ? 'https://' + window.location.host.replace('admin', 'api') + '/v1'
    : 'https://dev.api.liveby.com/v1'

//
// INITIAL STATE
//

export const initialState = {
  boundarySubtypes: ['neighborhood'],
  cityFilter: '',
  cityLookupStatus: '',
  cities: [],
  city: null,
  cityData: {},
  datasets: [],
  neighborhoodsLookupStatus: '',
  neighborhoodUploadStatus: {},
  neighborhoodSaveStatus: '',
  neighborhoods: [],
  neighborhood: null,
  neighborhoodLookupStatus: '',
  toasts: [],
  verifyStatus: '',
  user: {},
  accounts: null
}

//
// ACTION TYPES
//

export const BOUNDARY_SUBTYPES = 'BOUNDARY_SUBTYPES'
export const CITY = 'CITY'
export const CITY_FILTER = 'CITY_FILTER'
export const CITY_LOOKUP = 'CITY_LOOKUP'
export const CITY_DATA = 'CITY_DATA'
export const NEIGHBORHOOD = 'NEIGHBORHOOD'
export const NEIGHBORHOOD_SAVE = 'SAVE_NEIGHBORHOOD'
export const NEIGHBORHOOD_REMOVE = 'REMOVE_NEIGHBORHOOD'
export const NEIGHBORHOOD_UPLOAD = 'UPLOAD_NEIGHBORHOOD'
export const NEIGHBORHOODS_LOOKUP = 'NEIGHBORHOODS_LOOKUP'
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS'
export const VERIFY = 'VERIFY'
export const SET_USER = 'SET_USER'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const DATASET_UPDATE = 'DATASET_UPDATE'

//
// ACTIONS
//
let cityLookups = 0
export function setUser(user) {
  return {
    type: SET_USER,
    payload: user
  }
}

export async function paginate(func) {
  let finished = false
  let index = 0
  while (!finished) {
    finished = await func(index)
    index++
  }
}

export function lookupAccounts(account) {
  return async (dispatch, getState) => {
    const limit = 50
    console.log('starting page')
    await paginate(async (idx) => {
      const res = await fetch(
        `${API}/brokerage-update/get-accounts?limit=${limit}&offset=${
          idx * limit
        }&${account ? `?shortname=${account}` : ''}`,
        {
          credentials: 'include',
          mode: 'cors'
        }
      ).catch(console.error)
      if (!res || !res.ok) {
        const errorToast = addToast({
          type: 'error',
          text: 'Accounts lookup failure'
        })
        dispatch(errorToast)
        return true
      }
      const accounts = await res.json()

      dispatch({
        type: SET_ACCOUNTS,
        payload: [...(getState().accounts || []), ...accounts]
      })
      return accounts.length < limit
    })
  }
}

export function saveAccount(account, changes) {
  return async (dispatch, getState) => {
    const res = await fetch(`${API}/brokerage-update/update`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify({
        shortname: account.shortname,
        set: changes
      })
    })
    if (!res || !res.ok) {
      const errorToast = addToast({ type: 'error', text: 'Account not saved' })
      dispatch(errorToast)
      return
    }
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: await res.json()
    })
    dispatch(
      addToast({
        text: 'Account Saved!',
        type: 'success'
      })
    )
  }
}

export function lookupCity(name, type) {
  return function (dispatch, getState) {
    // Do not fire a city lookup until there are at least three key strokes
    if (!name || name.length < 2) {
      // We aren't currently styling when `cityLookupStatus === 'waiting'`
      // but we can
      return dispatch({
        type: CITY_LOOKUP,
        payload: { status: 'waiting', cities: [] }
      })
    }

    // Notify the UI that a lookup is pending
    dispatch({ type: CITY_LOOKUP, payload: { status: 'pending' } })

    const lid = ++cityLookups
    fetch(
      `${API}/lookup/boundaries?text=${encodeURIComponent(name)}${
        type ? `&community_type=${encodeURIComponent(type)}` : ''
      }`,
      { credentials: 'include', mode: 'cors' }
    )
      .then(checkStatus)
      .then((cities) => {
        if (cityLookups !== lid) {
          return /* Lookup is stale */
        }

        dispatch({
          type: CITY_LOOKUP,
          payload: {
            cities,
            status: 'completed'
          }
        })
      })
      .catch(dispatchError(dispatch, CITY_LOOKUP))
  }
}

export function getCity(id) {
  return function (dispatch, getState) {
    return fetch(`${API}/lookup/boundary?id=${id}`, {
      credentials: 'include',
      mode: 'cors'
    })
      .then(checkStatus)
      .then((response) => {
        dispatch({
          type: CITY_DATA,
          payload: response
        })
      })
      .catch((e) => {
        dispatch(
          addToast({
            text: `Failed to lookup the selected city (${id})`,
            type: 'error'
          })
        )
        dispatchError(dispatch, CITY_DATA)
        console.error(e)
      })
  }
}

export function setCityFilter(text) {
  return { type: CITY_FILTER, payload: { text } }
}

export function clearCityList() {
  return { type: CITY_LOOKUP, payload: { cities: [] } }
}

export function selectCity(city) {
  return async function (dispatch, getState) {
    dispatch({ type: CITY, payload: city })
    dispatch(clearCityList())
    await getCity(city)(dispatch, getState)
    console.log('Retreiving Neighborhoodsfor %s', city)
    // GET THOSE NEIGHBORHOODS!!
    dispatch({ type: NEIGHBORHOODS_LOOKUP, payload: { status: 'pending' } })
    return allNeighborhoodsIn(city, getState, dispatch).catch((e) => {
      dispatch(
        addToast({
          text: `Failed to populate the neighborhoods list for ${city}`,
          type: 'error'
        })
      )
      dispatchError(dispatch, NEIGHBORHOODS_LOOKUP)
      console.error(e)
    })
  }
}

export function reloadCity() {
  return async function (dispatch, getState) {
    await allNeighborhoodsIn(
      getState().cityData.properties.id,
      getState,
      dispatch
    )
  }
}

export function removeDuplicates() {
  return async function (dispatch, getState) {
    const state = getState()
    const info = addToast({
      type: 'info',
      text: 'Removing duplicates'
    })
    if (!state.cityData.properties || !state.cityData.properties.id) {
      return dispatch(
        addToast({
          type: 'error',
          text: 'Current city id could not be found'
        })
      )
    }
    dispatch(info)
    try {
      const res = await fetch(
        `${API}/remove-duplicates?place=${state.cityData.properties.id}`,
        {
          credentials: 'include'
        }
      )
      const ignored = await res.json()
      console.log(ignored)
      dispatch(removeToast(info.payload.id))
      dispatch(reloadCity())
    } catch (e) {
      console.error(e)
      dispatch(removeToast(info.payload.id))
      dispatch(
        addToast({
          type: 'error',
          text: 'Could not remove duplicates'
        })
      )
    }
  }
}

export function selectBoundarySubtypes(types) {
  return function (dispatch, getState) {
    const { cityData } = getState()
    dispatch({
      type: BOUNDARY_SUBTYPES,
      payload: types
    })
    if (cityData) {
      dispatch(selectCity(cityData.properties.id))
    }
  }
}

async function getNeighborhoodCount(city, boundarySubtypes) {
  try {
    // Remove this API because it's soo slow
    // Keep a set timeout here for timing reasons
    return -1
    // const { count } = await fetch(
    //   `${API}/count/neighborhoods?place=${encodeURIComponent(
    //     city
    //   )}&community_type=${boundarySubtypes.join(',')}`,
    //   {
    //     mode: 'cors',
    //     credentials: 'include'
    //   }
    // ).then(checkStatus)
    return count
  } catch (e) {
    return -1
  }
}

async function allNeighborhoodsIn(city, getState, dispatch) {
  const { boundarySubtypes } = getState()
  let nextNeighborhoods = { data: [], meta: {} }
  let neighborhoods = []
  const count = await getNeighborhoodCount(city, boundarySubtypes)

  let toast = addToast({
    type: 'progress',
    text: `Loading ${count} neighborhoods`,
    progress: 0
  })
  if (count < 0) {
    toast = addToast({
      type: 'info',
      text: 'Loading neighborhoods'
    })
  }
  dispatch(toast)
  do {
    // Check if our state has changed while we have paged
    if (
      (
        // If the list of boundary subtypes has changed, we need to stop loading this pagination
        !getState().boundarySubtypes.every(s => boundarySubtypes.includes(s)) ||
        !boundarySubtypes.every(s => getState().boundarySubtypes.includes(s))
      ) ||
      (
        // If the city has changed, we need to stop loading this pagination
        getState().cityData.properties &&
        city !== getState().cityData.properties.id)
    ) {
      dispatch(removeToast(toast.payload.id))
      return
    }
    nextNeighborhoods = await fetch(
      `${API}${
        nextNeighborhoods.meta.next
          ? nextNeighborhoods.meta.next
          : `/lookup/shapes?place=${encodeURIComponent(
              city
            )}&community_type=${boundarySubtypes.join(',')}`
      }`,
      {
        mode: 'cors',
        credentials: 'include'
      }
    )
      .then(checkStatus)
      .catch((e) => {
        console.log(e)
        return { data: [], meta: {} }
      })
    const withPageData = await getPageData(nextNeighborhoods.data, dispatch)
    neighborhoods = [...neighborhoods, ...withPageData]
    updateNeighborhoodsStore(neighborhoods, 'pending', dispatch)
    dispatch(
      addToast({
        ...toast.payload,
        progress: neighborhoods.length / count
      })
    )
  } while (nextNeighborhoods.meta.next)
  dispatch(removeToast(toast.payload.id))
  return updateNeighborhoodsStore(neighborhoods, 'completed', dispatch)
}

async function getPageData(neighborhoods, dispatch) {
  const res = await fetch(
    `${API}/update-pages/page-for-boundary?boundaries=${neighborhoods.map(
      (n) => n._id
    )}`,
    { credentials: 'include' }
  ).then(checkStatus)
  return neighborhoods.map((n) => ({
    ...n,
    meta: {
      ...(n.meta || {}),
      hasPage: res[n._id]
    }
  }))
}

function updateNeighborhoodsStore(neighborhoods, status, dispatch) {
  dispatch({
    type: NEIGHBORHOODS_LOOKUP,
    payload: {
      neighborhoods,
      status
    }
  })
}

export function selectNeighborhood(id) {
  return (dispatch) => {
    // Notify the UI that a lookup is pending
    dispatch({ type: NEIGHBORHOOD, payload: { status: 'pending' } })
    fetch(`${API}/neighborhood/${id}?dtm=${Date.now()}`)
      .then(checkStatus)
      .then(({ data: neighborhood }) => {
        dispatch({
          type: NEIGHBORHOOD,
          payload: {
            neighborhood,
            status: 'completed'
          }
        })
      })
  }
}

let id = 0

const defaultOptions = {
  type: 'info'
}

/**
 * ###createToast ({type, id, text, progress})
 *
 * Helper function called by `addToast()`
 *
 * Initializes an id if none are passed to `addToast()`
 * Adds any passed in options to the default options and returns an object.
 *
 */
export function createToast(options: {
  type: 'success' | 'error' | 'warning' | 'info' | 'progress',
  id?: number,
  text?: string,
  progress?: number
}) {
  return {
    ...defaultOptions,
    id: id || id++,
    ...options
  }
}

/**
 * ### addToast({type, id, text, progress})
 *
 * Initializes a toast notification when dispatched.
 *
 * - `options`: An Object which has the following properties:
 *   - `type`: An optional String that defines what type of notification the toast will be.
 *             Must be one of: "success", "error", "warning", "info", "progress".
 *
 * ##### Example:
 *
 *    addToast({
 *      type: 'success',
 *      text: 'You are successful beyond your wildest imaginings!'
 *    })
 *
 */
export function addToast(
  options: {
    type: 'success' | 'error' | 'warning' | 'info' | 'progress',
    id?: number,
    text?: string,
    progress?: number
  } = {}
) {
  return {
    type: ADD_TOAST,
    payload: createToast(options)
  }
}

/**
 * ### removeToast (id)
 *
 * Removes a toast notification when dispatched.
 *
 * - `id`: An identifier of the toast to be removed
 *
 * ##### Example:
 *
 *     removeToast(toast.id)
 *
 */
export function removeToast(id: number) {
  return {
    type: REMOVE_TOAST,
    payload: { id }
  }
}

export function removeAllToasts() {
  return {
    type: REMOVE_ALL_TOASTS
  }
}

export function verify(neighborhood) {
  return verification('verify', neighborhood._id)
}

export function unverify(neighborhood) {
  return verification('unverify', neighborhood._id)
}

function verification(endpoint, _id) {
  return function (dispatch) {
    dispatch({
      type: VERIFY,
      payload: { neighborhood: _id, status: 'pending' }
    })

    fetch(`${API}/verification/${endpoint}?_id=${_id}`, {
      credentials: 'include'
    })
      .then(checkStatus)
      .then(({ verified }) => {
        dispatch({
          type: VERIFY,
          payload: {
            status: 'completed',
            neighborhood: _id,
            verified
          }
        })
      })
      .catch((e) => {
        dispatch(
          addToast({
            text: 'Failed to update neighborhood verification status',
            type: 'error'
          })
        )
        dispatchError(dispatch, NEIGHBORHOOD_SAVE)
        console.error(e)
      })
  }
}

// Fetch distinct boundary types

export const getCommunityTypes = (function () {
  let retrieved = false
  let cachedTypes = [
    'community',
    'county',
    'countySubdivision',
    'district',
    'neighborhood',
    'schoolAttendanceArea',
    'schoolDistrict',
    'state',
    'subdivision',
    'urbanArea',
    'zipcode'
  ]
  return async function getCommunityTypes() {
    if (retrieved) return cachedTypes
    const result = await fetch(`${API}/lookup/boundary-types`, {
      credentials: 'include',
      mode: 'cors'
    })

    if (result.ok) {
      const types = await result.json()

      if (types) {
        cachedTypes = types
        retrieved = true
        return types.sort()
      }
    }

    // Fallback to a hard-coded list if error
    return cachedTypes
  }
})()

export function convertToCommunity(city) {
  return function (dispatch) {
    fetch(`${API}/city-to-community/?id=${city}`, {
      credentials: 'include'
    })
      .then(checkStatus)
      .then((result) => {
        dispatch(
          addToast({
            text: 'Successfully converted to a community',
            type: 'success'
          })
        )
        dispatch(selectCity(city))
      })
      .catch((e) => {
        dispatch(
          addToast({
            text: 'Failed to convert current city to a community',
            type: 'error'
          })
        )
        console.error(e)
      })
  }
}

export function getDatasets() {
  return async (dispatch) => {
    try {
      const res = await fetch(`${API}/lookup/datasets`, {
        mode: 'cors',
        credentials: 'include'
      })

      const ds = await res.json()
      dispatch({
        type: DATASET_UPDATE,
        payload: ds
      })
    } catch (e) {
      dispatch({
        type: DATASET_UPDATE,
        payload: []
      })
      setTimeout(() => getDatasets()(dispatch), 2000)
    }
  }
}

export function addToDataset(set, id) {
  return async function (dispatch, getState) {
    const { neighborhoods } = getState()
    const neighborhood = neighborhoods.find((n) => n._id === id)
    try {
      await saveNeighborhood({
        _id: id,
        properties: {
          access: Array.from(
            new Set([...set, ...(neighborhood.properties.access || [])])
          ),
          revoke: (neighborhood.properties.revoke || []).filter(
            (v) => !set.includes(v)
          )
        }
      })(dispatch, getState)
    } catch (e) {
      console.error(e)
    }
  }
}

export function removeFromSet(set, id) {
  return async function (dispatch, getState) {
    const { neighborhoods } = getState()
    const neighborhood = neighborhoods.find((n) => n._id === id)
    try {
      await saveNeighborhood({
        _id: id,
        properties: {
          revoke: Array.from(
            new Set([...(neighborhood.properties.revoke || []), ...set])
          )
        }
      })(dispatch, getState)
    } catch (e) {
      console.error(e)
    }
  }
}

export function uploadNeighborhoods(neighborhoods, city) {
  return async function (dispatch, getState) {
    const toast = addToast({
      type: 'progress',
      text: `Uploading ${neighborhoods.length} neighborhoods`,
      progress: 0
    })
    try {
      let amountDone = 0
      dispatch(toast)
      await PromiseMap(
        neighborhoods,
        async (neighborhood, idx) => {
          try {
            coordEach(neighborhood, (p) => {
              p[0] = round(p[0], 6)
              p[1] = round(p[1], 6)
            })
            // simplify(neighborhood, {tolerance: 0.000000001, mutate: true, highQuality: true})
            const n = await saveNeighborhood(
              {
                ...neighborhood,
                properties: {
                  ...neighborhood.properties,
                  places: [city],
                  label:
                    neighborhood.properties.label ||
                    `New Neighborhood ${idx + 1}`
                }
              },
              true
            )(dispatch, getState)
            console.log(n)
            if (n) {
              dispatch({ type: NEIGHBORHOOD_UPLOAD, payload: n })
            }
          } catch (e) {
            console.error(e)
          }
          amountDone++
          dispatch(
            addToast({
              ...toast.payload,
              progress: amountDone / neighborhoods.length,
              text: `Uploading ${neighborhoods.length} neighborhoods`
            })
          )
        },
        10
      )

      dispatch(
        addToast({
          ...toast.payload,
          progress: 1,
          text: `Successfully uploaded ${neighborhoods.length} neighborhoods!`,
          type: 'success'
        })
      )
      await new Promise((resolve, reject) => setTimeout(resolve, 1000))
    } catch (e) {
      dispatch(
        addToast({
          ...toast.payload,
          text: `Failed to upload ${neighborhoods.length} neighborhoods`,
          type: 'error'
        })
      )
      console.error(e)
    }
  }
}

export function saveNeighborhood(neighborhood, noToast = false) {
  return function (dispatch) {
    dispatch({ type: NEIGHBORHOOD_SAVE, payload: { status: 'pending' } })
    if (
      neighborhood.properties.archive &&
      neighborhood.properties.archive !== false
    ) {
      neighborhood.properties.brokerages = []
    }
    if (!neighborhood._id && neighborhood.properties._id) { // put _id from properties in top level to make sure neighborhood isnt duplicated.
      neighborhood._id = neighborhood.properties._id
    }
    const apiEndpoint = process.env.REACT_APP_ADMIN_API_URL || 'https://dev.admin.api.liveby.com'

    console.log({ apiEndpoint })
    console.log(JSON.stringify(process.env))

    return fetch(`${apiEndpoint}/update-neighborhood`, {
      method: 'POST',
      body: JSON.stringify({ neighborhood }),
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include'
    })
      .then(checkStatus)
      .then((updates) => {
        if (updates.properties.banned) {
          dispatch({
            type: NEIGHBORHOOD_REMOVE,
            payload: {
              status: 'completed',
              neighborhood: updates
            }
          })
        }
        dispatch({
          type: NEIGHBORHOOD_SAVE,
          payload: {
            status: 'completed',
            neighborhood: updates
          }
        })
        !noToast &&
        dispatch(
          addToast({
            type: 'success',
            text: 'Saved Neighborhood'
          })
        )
        return Promise.resolve()
          .then(
            () => new Promise((resolve, reject) => setTimeout(resolve, 1000))
          )
          .then(() =>
            dispatch({ type: NEIGHBORHOOD_SAVE, payload: { status: 'ready' } })
          )
          .then(() => updates)
      })
      .catch((e) => {
        dispatch(
          addToast({
            text: 'Failed to save neighborhood to the database',
            type: 'error'
          })
        )
        dispatchError(dispatch, NEIGHBORHOOD_SAVE)
        console.error(e)
      })
  }
}

//
// ACTION REDUCER
//

/**
 * reduce (state, action)
 * ======================
 * Handle an action by returning a new state tree in response to an action
 * being fired.
 *
 * Currently we are using a single reducer for simplicity.
 */
function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case CITY:
      return { ...state, city: payload.city }

    case CITY_FILTER:
      return { ...state, cityFilter: payload.text }

    case SET_USER:
      return { ...state, user: payload }

    case CITY_DATA:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            cityData: payload,
            cityFilter:
              payload && payload.properties.label
                ? cityName(payload)
                : state.cityFilter
          }

    case CITY_LOOKUP:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            cityLookupStatus: payload.status || state.cityLookupStatus,
            cities: payload.cities || state.cities
          }
    case BOUNDARY_SUBTYPES:
      return { ...state, boundarySubtypes: payload }
    case NEIGHBORHOOD:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            neighborhoodLookupStatus:
              payload.status || state.neighborhoodLookupStatus,
            neighborhood: payload.neighborhood || state.neighborhood
          }
    case NEIGHBORHOODS_LOOKUP:
      return error
        ? handleError(state, payload)
        : {
            ...state,
            neighborhoodsLookupStatus:
              payload.status || state.neighborhoodsLookupStatus,
            neighborhoods: payload.neighborhoods || state.neighborhoods
          }
      case NEIGHBORHOOD_UPLOAD:
        const existingIndex = state.neighborhoods.findIndex(
          (n) => n._id.toString() === payload._id.toString()
        )
          
        if (existingIndex !== -1) {
          // If a neighborhood object with the same _id already exists, replace it
          return {
            ...state,
            neighborhoods: [
              ...state.neighborhoods.slice(0, existingIndex),
              payload,
              ...state.neighborhoods.slice(existingIndex + 1),
            ],
          }
        } else {
          // If no neighborhood object with the same _id exists, insert the payload
          const idx = state.neighborhoods.findIndex(
            (a) =>
              a.properties.label
                .toLowerCase()
                .localeCompare(payload.properties.label.toLowerCase()) >= 0
          )
          return {
            ...state,
            neighborhoods: payload
              ? [
                  ...state.neighborhoods.slice(0, idx),
                  payload, 
                  ...state.neighborhoods.slice(idx)
                ]
              : state.neighborhoods
          }
        }
    case NEIGHBORHOOD_REMOVE:
      return {
        ...state,
        neighborhoodSaveStatus: payload.status || state.neighborhoodSaveStatus,
        neighborhoods:
          'neighborhood' in payload
            ? state.neighborhoods.filter((neighborhood) => {
              return neighborhood._id !== payload.neighborhood._id
            })
            : state.neighborhoods
      }
    case NEIGHBORHOOD_SAVE:
      return {
        ...state,
        neighborhoodSaveStatus: payload.status || state.neighborhoodSaveStatus,
        neighborhoods:
          'neighborhood' in payload
            ? state.neighborhoods.map((neighborhood) => {
              return neighborhood._id === payload.neighborhood._id
                ? { ...neighborhood, ...payload.neighborhood }
                : neighborhood
            })
            : state.neighborhoods,
        neighborhood:
          state.neighborhood &&
          payload.neighborhood &&
          payload.neighborhood._id === state.neighborhood._id
            ? { ...state.neighborhood, ...payload.neighborhood }
            : state.neighborhood
      }

    case DATASET_UPDATE: {
      return {
        ...state,
        datasets: payload
      }
    }
    case ADD_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id === payload.id)[0]
          ? state.toasts.map((toast) =>
            toast.id === payload.id ? payload : toast
          )
          : [...state.toasts, payload]
      }
    }
    case REMOVE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== payload.id)
      }
    }
    case REMOVE_ALL_TOASTS: {
      return {
        ...state,
        toasts: []
      }
    }
    case VERIFY: {
      return error
        ? handleError(state, payload)
        : {
            ...state,
            verifyStatus: payload.status || state.verifyStatus,
            neighborhoods:
              'verified' in payload
                ? state.neighborhoods.map((neighborhood) => {
                  return neighborhood._id === payload.neighborhood
                    ? {
                      ...neighborhood,
                      meta: {
                        ...neighborhood.meta,
                        verified: payload.verified
                      }
                    }
                    : neighborhood
                })
                : state.neighborhoods,
            neighborhood:
              state.neighborhood &&
              payload.neighborhood === state.neighborhood._id &&
              'verified' in payload
                ? {
                    ...state.neighborhood,
                    meta: {
                      ...state.neighborhood.meta,
                      verified: payload.verified
                    }
                  }
                : state.neighborhood
          }
    }
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: payload
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.map((ac) =>
          ac.shortname === payload.shortname ? payload : ac
        )
      }
    default:
      return state
  }
}

export default reducer

// Utility function to handle UI errors/notifications in a consistent manner.
// Probably should be handled by some redux middleware...
function handleError(state, error) {
  return {
    ...state,
    uiNotification: typeof error === 'string' ? error : error.message
  }
}

// Dispatch the error according to FSA
function dispatchError(dispatch, type) {
  return function errorDispatcher(err) {
    dispatch({ type, payload: err, error: true })
  }
}

// Check for server error (500) responses from API
function checkStatus(res) {
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return res.json()
}
