import statesConvert from '@liveby/states-convert'

const abbr = statesConvert('fips', 'abbr')

export function cityName({ _id, properties: { label, STATEFP, address } }) {
  return `${label}, ${address && address.state ? address.state : abbr(STATEFP)}`
}

export const authUrl =
  window.location.hostname === 'admin.liveby.com'
    ? 'https://api.liveby.com'
    : 'https://dev.api.liveby.com'

export const filterAccountPages = (account, communities) =>
  Object.keys(communities).map((city) =>
    communities[city].filter((page) =>
      page.properties.brokerages.includes(account.shortname)
    )
  )
